<template lang="pug">
  .error-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker {{ heading }}
    p.nio-p.text-primary-dark {{ description }}
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Close
</template>

<script>


export default {
  props: {
    heading: { type: String, required: false, default: 'Something went wrong' },
    description: { type: String, required: false, default: 'If the problem persists, please rech out to a member of the Narrative team' }
  },
  mounted() {
    parent.postMessage({
      name: 'errorDialog',
      payload: null
    },"*")
    setTimeout(() => {
      this.$emit('cancel')
    }, 300000); // allow 5 minutes before closing dialog
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.error-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 8px
    text-align: center
  p
    margin-bottom: 40px
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + *
      margin-left: 16px
</style>