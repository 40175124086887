<template lang="pug">
  .taxonomy-item-details
    .details
      h1.nio-h1.text-primary-darker {{ heading }}
      .form-controls(v-if="localItem")
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Name
            .description.nio-p.text-primary-dark Display name of segment in The Trade Desk.
          .filter-value
            NioTextField(
              v-model="localItem.displayName"
              label="Name"
            )
        .filter(v-if="itemType === 'Segment'")
          .title-description
            .filter-title.nio-h4.text-primary-darker Description
            .description.nio-p.text-primary-dark Description of segment to be displayed in The Trade Desk.
          .filter-value
            NioTextarea(
              v-model="localItem.description"
              label="Description"
            )
        .filter.full-path
          .title-description
            .filter-title.nio-h4.text-primary-darker Full Path
            .description.nio-p.text-primary-dark {{ fullPathText(item.fullPath) }}
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Element ID
            .description.nio-p.text-primary-dark Unique ID that identifies this segment in The Trade Desk.
          .filter-value
            NioTextField(
              v-model="localItem.id"
              label="Element ID"
            )
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Parent Element ID
            .description.nio-p.text-primary-dark Unique ID of the parent element.
          .filter-value
            NioTextField(
              v-model="localItem.parentElementId"
              label="Parent Element ID"
            )
        .filter(v-if="itemType === 'Segment'")
          .title-description
            .filter-title.nio-h4.text-primary-darker Data Stream ID
            .description.nio-p.text-primary-dark
          .filter-value
            NioTextField(
              v-if="localItem.dataStreamId"
              v-model="localItem.dataStreamId"
              label="Data Stream ID"
            )
            .nio-p.text-primary-dark(v-else) The Data Stream ID for this segment will be available after syncing.
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker {{ itemType }} Rate Card
            .description.nio-p.text-primary-dark The rate card determines what price this segment is available at, as well as which partners or advertisers have access to this segment in The Trade Desk.
          .filter-value
            p.nio-p.text-primary-dark Select the rate card to use
            NioTTDRateCardDetails(
              v-if="rateCard"
              :item="item"
              :rate-card="rateCard"
              :buyable="item.buyable"
            )
    .actions
      .navigation-controls
        NioButton(
          :disabled="index === 0"
          normal-secondary
          @click="prev"
        ) Previous
        NioButton(
          :disabled="index === count - 1"
          normal-secondary
          @click="next"
        ) Next
        .count.nio-p.text-primary-dark {{ index + 1 }} of {{ count }}
      .actions-controls
        NioButton.close(
          normal-secondary
          @click="close"
        ) Close
        //-  Save not supported for MVP
        //- NioButton.save(
        //-   normal-primary
        //-   @click="save"
        //- ) Save Changes
</template>

<script>

import { fullPathText, makeRateCardForItem } from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'

export default {

  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    mode: {
      type: String,
      required: true
    }
  },
  data: () => ({
    localItem: null,
    prevRateCard: null,
    rateCard: null
  }),
  computed: {
    itemType() {
      return this.item.isContainer ? 'Container' : 'Segment'
    },
    heading() {
      return this.mode === 'view' ? `${this.itemType} Details` : `New ${this.itemType}`
    },
    rateCardChanged() { // TODO
      return false
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.initDetails()
      }
    }
  },
  mounted() {
    this.initDetails()
  },
  methods: {
    initDetails() {
      this.localItem = JSON.parse(JSON.stringify(this.item))
      console.log(this.item)
      if (!this.localItem.description) this.localItem.description = 'No description provided'
      if (!this.localItem.dataStreamId) this.localItem.dataStreamId = ' '
      this.initRateCard()
    },
    initRateCard() {
      console.log(this.item)
      this.prevRateCard = makeRateCardForItem(this.item)
      this.rateCard = this.prevRateCard
    },
    inheritParentRateCard(item) {
      return !item.systemRateCard && !item.advertiserRateCard && !item.partnerRateCard
    },
    prev() {
      this.$emit('prev')
    },
    next() {
      this.$emit('next')
    },
    close() {
      this.$emit('close')
    },
    save() {
      this.$emit('save')
    },
    fullPathText(text) {
      return fullPathText(text)
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-group"

.taxonomy-item-details
  margin: 1rem auto
  .details
    padding-bottom: 5rem
    .form-controls
      +nio-filter-group
      margin-top: 1.5rem
      & > .filter + .filter
        border-top: 0.0625rem solid $c-primary-lighter
      .filter
        +nio-filter-header
        .nio-text-field, .nio-textarea
          width: 100%
        .filter-value // temp until edit allowed
          pointer-events: none
        &.full-path
          .description
            margin-top: 1rem
            font-family: 'Roboto Mono'
            font-style: normal
            font-weight: 400
            font-size: 0.75rem
            line-height: 1rem
            letter-spacing: 0.0313rem
  .actions
    display: flex
    justify-content: space-between
    position: absolute
    bottom: 3.25rem
    left: 2.0625rem
    padding: 1.5rem 1.5rem 2rem 1.5rem
    width: calc(100% - 4.125rem)
    border-bottom-left-radius: 0.75rem
    border-bottom-right-radius: 0.75rem
    background-color: $c-white
    box-shadow: 0.0625rem -0.125rem 0.5625rem -0.25rem rgba(203,208,214,0.7) //
    .navigation-controls
      display: flex
      justify-content: flex-start
      align-items: center
      & > * + *
        margin-left: 1rem
    .actions-controls
      display: flex
      justify-content: flex-end
      align-items: center
      & .nio-button + .nio-button
        margin-left: 1.5rem
</style>