import { render, staticRenderFns } from "./TaxonomyTableView.vue?vue&type=template&id=da6f0028&scoped=true&lang=pug&"
import script from "./TaxonomyTableView.vue?vue&type=script&lang=js&"
export * from "./TaxonomyTableView.vue?vue&type=script&lang=js&"
import style0 from "./TaxonomyTableView.vue?vue&type=style&index=0&id=da6f0028&prod&lang=sass&"
import style1 from "./TaxonomyTableView.vue?vue&type=style&index=1&id=da6f0028&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da6f0028",
  null
  
)

export default component.exports