const Filters = [
  {
    label: 'Buyable Elements Only',
    name: 'buyable',
  },
  {
    label: 'Synced Elements Only',
    name: 'synced',
  },
  {
    label: 'Rate Cards',
    name: 'rateCards',
    customControls: {
      systemRateCard: true,
      partnerRateCard: true,
      advertiserRateCard: true
    }
  },
  {
    label: 'Active IDs',
    name: 'activeIdsCount',
    customControls: {
      min: 0,
      max: null,
      value: [0,50]
    }
  },
  {
    label: 'Received IDs',
    name: 'receivedIdsCount',
    customControls: {
      min: 0,
      max: null,
      value: [50, 100]
    }
  }
]

export default Filters