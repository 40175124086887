<template lang="pug">
  .taxonomy-table-view
    v-data-table.taxonomy-table(
      v-if="headers && items"
      :headers="headers"
      :items="items"
      :hide-default-footer="true"
      overflow-x="scroll"
      disable-pagination
    )
      template(v-slot:item.displayName="{ item }")
        HoverableTableCell(
          :item="item"
          column-name="displayName"
        )
          span.display-name
            NioIcon(
              :color="getThemeColor('primaryDark')"
              :name="getItemIcon(item)"
              :size="16"
            )
            span.display-name-text {{ item.displayName }}
      template(v-slot:item.id="{ item }")
        HoverableTableCell(
          :item="item"
          column-name="id"
        ) {{ item.id }}
      template(v-slot:item.fullPath="{ item }")
         HoverableTableCell(
          :item="{...item, fullPath: fullPathText(item.fullPath)}"
          column-name="fullPath"
        )
          span {{ fullPathText(item.fullPath) }}
      template(v-slot:item.description="{ item }")
        HoverableTableCell(
          :item="item"
          :max-width="300"
          column-name="description"
        ) {{ item.description }}
      template(v-slot:item.parentElementId="{ item }")
        HoverableTableCell(
          :item="item"
          column-name="parentElementId"
        ) {{ item.parentElementId }}
      template(v-slot:item.dataStreamId="{ item }")
        HoverableTableCell(
          :item="item"
          column-name="dataStreamId"
        ) {{ item.dataStreamId }}
      template(v-slot:item.effectivePrice="{ item }")
        NioTTDRateCardSummary(
          :item="item"
          type="bespoke"
        )
      template(v-slot:item.activeIdsCount="{ item }")
        span {{ formatIdCount(item.activeIdsCount)}}
      template(v-slot:item.receivedIdsCount="{ item }")
        span {{ formatIdCount(item.receivedIdsCount)}}
      template(v-slot:item.buyable="{ item }")
        span {{ item.buyable ? 'YES' : 'NO'}}
      template(v-slot:item.synced="{ item }")
        span(:class="{'text-seagreen-light': item.synced, 'text-coral-light': !item.synced}") {{ item.synced ? 'Synced' : 'Not Synced'}}
      template(v-slot:item.actions="{ item }")
        .node-actions
          NioButton(
            normal-tertiary
            @click="viewDetails(item)"
          ) View Details
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import HoverableTableCell from './HoverableTableCell.vue'
import { makeRandomId, formatNumberVerbose } from '@narrative.io/tackle-box/src/modules/helpers'
import { TableColumns } from './columns'
import { fullPathText } from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'
import Fuse from 'fuse.js'

export default {
  components: { HoverableTableCell },
  props: {
    taxonomy: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    filters: {
      type: Array,
      required: true
    },
    activeFilterIndeces: {
      type: Array,
      required: true
    },
    searchTerm: {
      type: String,
      required: false
    }
  },
  data: () => ({
    headers: null,
    items: null,
    tableKey: null,
    fuseInstance: null,
    searchOptions: {
      findAllMatches: true,
      keys: ['displayName', 'id']
    },
  }),
  watch: {
    columns() {
      this.generateTableData()
    },
    activeFilterIndeces() {
      this.generateTableData()
    },
    filters: {
      deep: true,
      handler() {
        this.generateTableData()
      }
    },
    searchTerm() {
      this.generateTableData()
    }
  },
  mounted() {
    this.tableKey = makeRandomId()
    this.generateTableData()
  },
  methods: {
    generateTableData() {
      this.tableKey = makeRandomId()
      this.headers = [
        ...this.columns.map(column => {
          return {
            text: column.label,
            value: column.name,
            sortable: column.sortable ? true : false,
            class: column.class,
            cellClass: column.class,
            width: column.width
          }
        }).sort((a, b) => TableColumns.findIndex(tableColumn => a.value === tableColumn.name) - TableColumns.findIndex(tableColumn => b.value === tableColumn.name)),
        {
          text: '',
          value: 'actions',
          sortable: false,
          cellClass: null,
          width: 150
        }
      ]
      this.applyFilters()
      this.applyTableStyles()
    },
    applyFilters() {
      let taxonomyCopy = JSON.parse(JSON.stringify(this.taxonomy))
      this.activeFilterIndeces.forEach(index => {
        const filter = this.filters[index]
        taxonomyCopy = this.applyActiveFilter(filter, taxonomyCopy)
      })
      this.items = this.applySearch(taxonomyCopy)
    },
    applyActiveFilter(filter, taxonomy) {
      switch (filter.name) {
        case 'buyable':
          return taxonomy.filter(item => item.buyable)
        case 'synced':
          return taxonomy.filter(item => item.synced)
        case  'rateCards':
          return taxonomy.filter(item => {
            if (Object.keys(filter.customControls).find(key => filter.customControls[key] && !item[key])) {
              return false
            }
            return true
          })
        case 'activeIdsCount':
          return taxonomy.filter(item => item.activeIdsCount >= filter.customControls.value[0] && item.activeIdsCount <= filter.customControls.value[1])
        case 'receivedIdsCount':
          return taxonomy.filter(item => item.receivedIdsCount >= filter.customControls.value[0] && item.receivedIdsCount <= filter.customControls.value[1])
        default:
          break;
      }
    },
    applySearch(taxonomy) {
      if (this.searchTerm && this.searchTerm.length >= 0) {
        this.fuseInstance = new Fuse(taxonomy, this.searchOptions)
        this.fuseInstance.search(this.searchTerm)
        return this.fuseInstance.search(this.searchTerm).map(result => result.item)
      }
      return taxonomy
    },
    applyTableStyles() {
      this.$nextTick(() => {
        const tableWrapper = document.querySelector(`.taxonomy-table`)
        const tableEl = document.querySelector(`table`)
        const allColumnsWidth = this.headers.reduce((acc, header) => {
          return acc + header.width
        }, 0)
        const tableWdth = Math.max(
          allColumnsWidth,
          980 // 100% container width
        )
        tableWrapper.style.width = `${tableWdth}px`
        tableEl.style.width = `${tableWdth}px`
      })
    },
    getThemeColor(colorName) {
      return getThemeColor(colorName)
    },
    getItemIcon(item) {
      return item.isContainer ? 'utility-folder' : 'utility-file'
    },
    viewDetails(item) {
      this.$emit('viewDetails', item)
    },
    formatIdCount(val) {
      const formattedVal = formatNumberVerbose(val)
      return val ? formattedVal : '0'
    },
    fullPathText(text) {
      return fullPathText(text)
    }
  }
};
</script>

<style lang="sass">

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.table-item-tooltip
  background-color: $c-white
  border-radius: 0.25rem
  padding: 0.5rem
</style>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/table/_slat-table"

=header-type
  font-family: 'Euclid Circular A'
  font-size: 0.625rem
  font-weight: 600
  line-height: 1.25rem
  letter-spacing: 0.05rem
  text-align: left
  height: 2.25rem
  text-transform: uppercase
=cell-type
  font-family: 'Roboto Mono'
  font-style: normal
  font-weight: 400
  font-size: 0.75rem
  line-height: 1rem
  letter-spacing: 0.0313rem
.taxonomy-table-view
  .v-data-table
    +nio-slat-table
    overflow-x: auto
    ::v-deep table
      table-layout: fixed
      overflow: hidden
    ::v-deep thead
      tr
        background-color: $c-canvas
      th
        +header-type
        .v-data-table-header__icon
          margin-left: 0.3125rem
          margin-bottom: 0.1875rem
          &:before
            height: 0.5rem
    ::v-deep td, ::v-deep th
      white-space: nowrap
      color: $c-primary-dark !important
    ::v-deep tbody
      td
        +cell-type
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis
        position: relative
        span
          cursor: default
        .display-name
          .nio-icon
            margin-right: 0.875rem
          .display-name-text
            font-weight: 700
    .node-actions
      height: 100%
      display: flex
      justify-content: flex-end
      align-items: center
      .nio-button
        display: none
    .nio-button
      display: block
  ::v-deep tr:hover
    &:hover
      background-color: $c-canvas-darker !important
    .node-actions .nio-button
      display: flex
  .full-cell-display-text
    position: fixed
    bottom: 1.875rem
</style>