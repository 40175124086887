<template lang="pug">
  .uploading-dialog(ref="dialog")
    v-progress-circular.progress(
      :value="percentComplete"
      :rotate="-90"
      size="80"
      color="#1438F5"
    )
    h3.nio-h3.text-primary-darker Syncing Taxonomy
    p.nio-p.text-primary-dark This could take awhile depending on the size of your taxonomy

</template>

<script>

export default {
  props: {
    executedCount: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  computed: {
    percentComplete() {
      return Math.round((this.executedCount / this.totalCount) * 100)
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.uploading-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  h3
    margin-bottom: 0.5rem
    margin-top: 5rem
  p
    margin-bottom: 2.5rem
  .v-progress-circular
    position: absolute
    top: 3.25rem
    z-index: 2
</style>