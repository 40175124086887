const TableColumns = [
  {
    label: 'Display Name',
    name: 'displayName',
    class: 'display-name',
    width: 250
  },
  {
    label: 'Price',
    name: 'effectivePrice',
    class: 'price',
    width: 170
  },
  {
    label: 'Received IDs',
    name: 'receivedIdsCount',
    class: 'received-ids',
    width: 140,
    sortable: true
  },
  {
    label: 'Active IDs',
    name: 'activeIdsCount',
    class: 'active-ids',
    width: 140,
    sortable: true
  },
  {
    label: 'Sync Status',
    name: 'synced',
    class: 'sync-status',
    width: 120,
    sortable: true
  },
  {
    label: 'Full Path',
    name: 'fullPath',
    class: 'full-path',
    width: 300
  },
  {
    label: 'Buyable',
    name: 'buyable',
    class: 'buyable',
    width: 90
  },
  {
    label: 'Element ID',
    name: 'id',
    class: 'element-id',
    width: 270
  },
  {
    label: 'Parent ID',
    name: 'parentElementId',
    class: 'parent-id',
    width: 270
  },
  {
    label: 'Data Stream ID',
    name: 'dataStreamId',
    class: 'data-stream-id',
    width: 270
  },
  {
    label: 'Description',
    name: 'description',
    class: 'description',
    width: 350
  },
]
const initiallySelectedColumnNames = ['displayName', 'effectivePrice', 'receivedIdsCount', 'activeIdsCount', 'synced']
const initiallySelectedTableColumns = initiallySelectedColumnNames.map(columnName => TableColumns.find(column => column.name === columnName))

export {
  TableColumns,
  initiallySelectedTableColumns
}