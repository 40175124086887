<template lang="pug">
  v-tooltip(
    :open-delay="150"
    :max-width="maxWidth ? maxWidth : null"
    content-class="table-item-tooltip"
    bottom
  )
    .nio-p.text-primary-dark {{ item[columnName] }}
    template(v-slot:activator="{ on, attrs }")
      span(
        v-bind="attrs"
        v-on="on"
      )
        slot
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    columnName: {
      type: String,
      required: true
    },
    maxWidth: {
      type: Number,
      required: false
    }
  }
};
</script>

<style lang="sass">
@import "@narrative.io/tackle-box/src/styles/global/_colors"
.table-item-tooltip
  background-color: rgba(255, 255, 255, 1)
  border-radius: 0.25rem
  padding: 0.5rem
  box-shadow: 0rem 0rem 0.5625rem -0.25rem rgba(203,208,214,0.9)

</style>
