<template lang="pug">
  .upload-dialog
    NioFileChooser(
      v-model="file"
      :validate-fn="() => true"
      :state="chooserState"
      :valid="fileValid"
      :percentComplete="10"
      :maxFileSize="1024*1024*10"
      instructions="Drag and drop taxonomy data "
      actionLabel="Generate Taxonomy"
      successMsg="Your file contains 12,345 valid IDs and 0 errors."
      validationErrorMsg="Your file does not contain any valid IDs."
      indeterminate
      @changed="validateFile($event)"
      @stateChanged="setState($event)"
      @actionClicked="parseFile($event)"
    )
      template(v-slot:success-actions)
        NioButton(
          normal-secondary
          @click="reset"
        ) Reset
        NioButton(
          normal-primary
          @click="processFile"
        ) Process File
      template(v-slot:error-actions)
        NioButton(
          normal-primary
          @click="reset"
        ) Start Over
</template>

<script>

import { attachFullPathsToTaxonomy } from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'

export default {
  data: () => ({
    chooserState: 'initial',
    file: null,
    fileValid: false,
    taxonomyError: false
  }),
  methods: {
    setState(val) {
      this.chooserState = val
    },
    reset() {
      this.chooserState = 'initial'
      this.percentComplete = 0
    },
    parseFile() {
      let reader = new FileReader()
      reader.onload = e => {
        const csvData = this.$papa.parse(e.target.result, {header: true})
        const taxonomy = this.createTaxonomy(csvData)

        if (!this.taxonomyError) {
          this.$emit('taxonomyCreated', taxonomy)
        }
      }
      reader.readAsText(this.file)
    },
    validateFile(val) {
      this.file = val[0]
      if (this.file.type === 'text/csv' || this.file.type === 'text/txt') {
        this.fileValid = true
      } else {
        this.fileValid = false
      }
    },
    createTaxonomy(csvData) {
      this.taxonomyError = false
      const taxonomy = []
      try {
        csvData.data.forEach(row => {
        const taxonomyItem = this.createTaxonomyItem(row)
          taxonomy.push(taxonomyItem)
        })
        attachFullPathsToTaxonomy(taxonomy)
        return taxonomy
      } catch (e) {
        this.taxonomyError = true
        this.$emit('taxonomyError', e)
      }
    },
    createTaxonomyItem(item) {
      const requiredProperties = [
        "id",
        "parentElementId",
        "companyId",
        "displayName"
      ]
      const newItem = {
        id: item.ttd_provider_element_id,
        companyId: this.nioUser.companyId,
        parentElementId: this.makeOptionalProperty(item.ttd_parent_element_id, "null"),
        buyable: item.buyable === 'YES',
        displayName: item.display_name,
        description: this.makeOptionalProperty(item.description, ""),
        effectivePrice: null,
        fullPath: null,
        systemRateCard: this.makeRateCard(item.system_rate_card),
        partnerRateCard: this.makeRateCard(item.partner_rate_card),
        advertiserRateCard: this.makeRateCard(item.advertiser_rate_card),
        dataStreamId: this.makeOptionalProperty(item.nio_data_stream_id, ""),
        audienceSize: null,
        receivedIdsCount: null,
        activeIdsCount: null,
        activeIdsCountExpanded: null,
        synced: false,
        hasChanged: false
      }
      if (requiredProperties.every(prop => newItem[prop] && newItem[prop] !== '')) {
        return newItem
      } else {
        throw new Error("Missing required property")
      }
    },
    makeOptionalProperty(value, nullString) {
      return !value || value === nullString ? null : value
    },
    makeRateCard(rateCard) {
      if (!rateCard) return null
      rateCard = JSON.parse(rateCard)
      const data = rateCard.partners ? rateCard.partners : rateCard.advertisers
      if (data && data.length > 0) {
        return {
          revenueShare: data[0].rev_share,
          cpmCap: data[0].cpm_cap,
          ids: data.reduce((acc, obj, index) =>{
            if (index > 0) {
              acc += '\n'
            }
            acc += obj.partner_id ? obj.partner_id : obj.advertiser_id
            return acc
          }, '')
        }
      } else if (rateCard.rev_share && rateCard.cpm_cap) {
        return {
          revenueShare: rateCard.rev_share * 100,
          cpmCap: rateCard.cpm_cap
        }
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.test-file-chooser
  .nio-file-chooser
    margin-bottom: 6.25rem
</style>