<template lang="pug">
  .taxonomy-tree-view
    .tree-container(v-if="taxonomy.length > 0")
      ul
        li(
          v-for="item of taxonomy"
          :class="nestDepthClass(item)"
        )
          div.nest-boundary(
            :class="{'has-next-sibling': hasNextSibling(item, index)}"
            v-for="index in getNestDepth(item)"
          )
            .boundary-content
          span.item-content
            .content
              NioIcon(
                :color="getThemeColor('primaryDark')"
                :name="getItemIcon(item)"
                :size="18"
              )
              .nio-p.text-primary-dark {{ item.displayName }}
              NioTTDRateCardSummary(
                :item="item"
                :as-pill="true"
                type="bespoke"
              )
            .item-actions
              NioButton(
                normal-tertiary
                @click="viewDetails(item)"
              ) Details
            span.mask-spacer

</template>

<script>

import { PathDelimiter, areSamePaths } from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'
import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'

export default {
  components: { },
  props: {
    "taxonomy": { type: Array, required: true }
  },
  data: () => ({
  }),
  watch: {

  },
  mounted() {
  },
  methods: {
    getNestDepth(item) {
      const splitPath = item.fullPath.split(PathDelimiter)
      return splitPath.length - 1
    },
    nestDepthClass(item) {
      return `nest-${this.getNestDepth(item)}`
    },
    hasNextSibling(item, nest) {
      const itemIndex = this.taxonomy.findIndex(taxonomyItem => item.id === taxonomyItem.id)
      const nextItems = this.taxonomy.slice(itemIndex + 1)
      const siblingItem = nextItems.find(nextItem => {
        const splitPath = item.fullPath.split(PathDelimiter)
        const splitNextPath = nextItem.fullPath.split(PathDelimiter)
        const slicedPath = splitPath.slice(0, nest + 1)
        const slicedNextPath = splitNextPath.slice(0, nest + 1)
        if (areSamePaths(slicedPath.slice(0, nest), slicedNextPath.slice(0, nest)) && slicedPath[nest] !== slicedNextPath[nest] ) {
          return true
        }
        return false
      })
      if (siblingItem) {
        return true
      }
      return false
    },
    getThemeColor(colorName) {
      return getThemeColor(colorName)
    },
    getItemIcon(item) {
      return item.isContainer ? 'utility-folder' : 'utility-file'
    },
    viewDetails(item) {
      console.log(item)
      this.$emit('viewDetails', item)
    }
	}
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.taxonomy-tree-view
  text-align: left
  width: 100%
  .tree-container
    border: 0.0625rem solid $c-primary-lighter
    background-color: $c-white
    border-radius: 0.75rem
    padding: 1rem 1.5rem
    margin-top: 1.5rem
  ul
    padding-left: 0rem
  li
    list-style: none
    display: flex
    height: 2.125rem
    padding: 0px 16px
    border-radius: 12px
    .nest-boundary
      width: 2.625rem
      margin-right: 0.625rem
      position: relative
      .boundary-content
        position: absolute
        top: -50%
        left: 0
        height: 100%
        width: 100%
    .item-content
      position: relative
      display: flex
      justify-content: space-between
      margin-top: 1px
      flex-grow: 2
      .content
        display: flex
        align-items: center
        .nio-icon
          margin-left: -7px
          z-index: 1
        .nio-p
          margin-left: 8px
        .nio-ttd-rate-card-summary
          margin-left: 8px
      .item-actions
        .nio-button
          height: 34px !important
          margin-top: 0px
          display: none
          pointer-events: all !important
      .mask-spacer
        position: absolute
        width: 0.3125rem
        height: 1.0375rem
        background-color: white
        // background-color: rgba(0,0,0,0.12)
        left: -0.15rem
        bottom: 2px
    &:hover
      background-color: $c-canvas
      *:not(.nio-button)
        cursor: default !important
      .item-actions .nio-button
        display: flex
        z-index: 0
    .nio-button
      width: 0.625rem
      height: 0.625rem
      border: none !important
      background-color: rgba(0,0,0,0) !important
      margin-left: 0.625rem
      margin-top: 0.125rem
      ::v-deep svg
        color: $c-primary-light
    div:last-of-type
      .boundary-content
        border-bottom: 0.0625rem solid $c-primary-dark
        border-left: 0.0625rem solid $c-primary-dark
    .has-next-sibling
      .boundary-content
        border-left: 0.0625rem solid $c-primary-dark
        top: -50%
        height: 100%
  // i'm sure there's a better way to do this with SASS, but this will work for now
  .nest-0 + .nest-1,
  .nest-1 + .nest-2,
  .nest-2 + .nest-3,
  .nest-3 + .nest-4,
  .nest-4 + .nest-5,
  .nest-5 + .nest-6,
  .nest-6 + .nest-7,
  .nest-7 + .nest-8
    .nest-boundary:not(.has-next-sibling)
      .boundary-content
        top: -30%
        height: 80%
      .nio-button
        display: inline-block
        margin-left: -1.75rem
.actions
  display: flex
  justify-content: center
  align-items: center
  margin-top: 1.5rem
  & > * + *
    margin-left: 1rem
</style>