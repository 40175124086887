<template lang="pug">
  .sync-results-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-email"
    )
    h3.nio-h3.text-primary-darker  {{ heading }}
    p.nio-p.text-primary-dark {{ message }}
    .actions
      NioButton(
        normal-secondary
        @click="$emit('ok')"
      ) Ok
</template>

<script>

export default {
  props: {
    error: {
      type: Object,
      required: true
    }
  },
  computed: {
    heading() {
      switch (this.error.type) {
        case 'existingTaxonomy':
        case 'upload':
          return 'Something went wrong...'
        case 'sync':
          return 'Sync Complete'
        default:
          return 'Sync Complete'
      }
    },
    message() {
      switch (this.error.type) {
        case 'existingTaxonomy':
          return 'There is a problem with your existing taxonomy. Please contact a memeber of the Narrative team for further assistance.'
        case 'upload':
          return 'We were unable to create your taxonomy from the file you provided. Please check the file for errors and try again. Please contact a Narrative team member if you continue to experience problems.'
        case 'sync':
          return 'Your taxonomy was synced, but there were some issues with one or more taxonomy elements. Please contact a member of the Narrative team for assistance.'
        default:
          return 'Everything went smoothly. Click OK to view your synced taxonomy.'
      }
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.sync-results-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
    margin-top: 5rem
  p
    margin-bottom: 2.5rem
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + *
      margin-left: 1rem

  .v-progress-circular
    position: absolute
    top: 3.25rem
    z-index: 2
</style>