<template lang="pug">
  .no-profile-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-email"
    )
    h3.nio-h3.text-primary-darker No profile found
    p.nio-p.text-primary-dark You need to create a profile before you can upload or manage your taxonomy.
    .actions
      NioButton(
        normal-secondary
        @click="$emit('ok')"
      ) Ok
</template>

<script>

export default {
  methods: {
    ok() {
      this.$emit('cancel')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.no-profile-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
    margin-top: 5rem
  p
    margin-bottom: 2.5rem
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + *
      margin-left: 1rem

  .v-progress-circular
    position: absolute
    top: 3.25rem
    z-index: 2
</style>