import { render, staticRenderFns } from "./TaxonomyTreeView.vue?vue&type=template&id=fab970d8&scoped=true&lang=pug&"
import script from "./TaxonomyTreeView.vue?vue&type=script&lang=js&"
export * from "./TaxonomyTreeView.vue?vue&type=script&lang=js&"
import style0 from "./TaxonomyTreeView.vue?vue&type=style&index=0&id=fab970d8&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fab970d8",
  null
  
)

export default component.exports