<template lang="pug">
  .taxonomy-listing
    .controls
      .view-selection
        .radio.table
          input#view-selection-table(
            type='radio'
            name='radio'
            value='table'
            checked
            v-model="controls.view"
          )
          label(for="view-selection-table")
            NioIcon(
              :size="18"
              name="utility-numbered-list"
            )
        .radio.tree
          input(
            type='radio',
            name='radio',
            value='tree',
            v-model="controls.view"
          )#view-selection-tree
          label(for="view-selection-tree")
            NioIcon(
              :size="18"
              name="utility-folder-tree"
            )
      NioTextField.search-input(
        v-model="controls.searchTerm"
        :class="{'control-disabled': controls.view === 'tree'}"
        placeholder="Search by Name or ID"
        search-small-subdued
      )
      NioSelect(
        v-if="controls.columns"
        v-model="controls.columns"
        :items="controls.columnOptions"
        :class="{'control-disabled': controls.view === 'tree'}"
        item-text="label"
        item-value="name"
        multiple
        small
        return-object
      )
        template(v-slot:selection="{ item, index }")
          .nio-p.text-primary-dark(v-if="index === 0") {{ selectColumnsLabel }}
      v-menu.filter-control-menu(
        :close-on-content-click="false"
        :ripple="false"
        :class="{'control-disabled': controls.view === 'tree'}"
        attach=".filter-control-activator"
      )
        template(v-slot:activator='{ on, attrs }')
          .filter-control-activator
            NioSelect(
              :label="`Add Filters (${controls.activeFilterIndeces.length})`"
              :items="[]"
              :class="{'control-disabled': controls.view === 'tree'}"
              v-model="controls.filters"
              small
              disabled
              multiple
            )
            button.filter-control-activator-btn(
              v-bind='attrs'
              v-on='on'
              :ripple="false"
              :class="{'control-disabled': controls.view === 'tree'}"
            )
        v-list.filter-control.container(:ripple="false")
          v-list-item-group(
            v-model="controls.activeFilterIndeces"
            multiple
          )
            v-list-item(
              v-for="filter in controls.filters"
              :key="filter.name"
              :ripple="false"
              :disabled="filterDisabled(filter)"
            )
              template(v-slot:default="{ active }")
                v-list-item-action
                  NioCheckbox(
                    :input-value="active"
                    :label="filter.label"
                  )
                .custom-filter-controls(
                  v-if="filter.customControls"
                  @click.stop=""
                )
                  template(v-if="filter.name === 'activeIdsCount' || filter.name ==='receivedIdsCount'")
                    v-range-slider(
                      v-if="showFilter(filter)"
                      v-model="controls.sliderModels[filter.name]"
                      :min="filter.customControls.min"
                      :max="filter.customControls.max"
                      :color="getThemeColor('primary')"
                      thumb-label="always"
                      strict
                      @change="sliderChanged(filter, $event)"
                    )
                      template(v-slot:thumb-label="{ value }")
                        .nio-p.text-primary-dark.nio-bold {{ formaSliderThumbLabel(value) }}
                    .no-filter-ids(v-else)
                      .nio-p.text-primary-darker.nio-bold No available IDs
                  template(v-if="filter.name === 'rateCards'")
                    .rate-card-filters
                      .nio-p.text-primary-darker.nio-bold Show elements that have any of:
                      NioSwitch(
                        v-model="filter.customControls.systemRateCard"
                        label="System-level Rate Card"
                      )
                      NioSwitch(
                        v-model="filter.customControls.partnerRateCard"
                        label="Partner-level Rate Card"
                      )
                      NioSwitch(
                        v-model="filter.customControls.advertiserRateCard"
                        label="Advertiser-level Rate Card"
                      )
      .table-scroll-message.nio-p.text-primary-dark(v-if="tableExceedsWidth") scroll
        NioIcon(
          name="utility-arrow-right"
        )
    .display
      TaxonomyTableView(
        v-if="controls.view === 'table'"
        :taxonomy="taxonomy"
        :columns="controls.columns"
        :filters="controls.filters"
        :active-filter-indeces="controls.activeFilterIndeces"
        :search-term="controls.searchTerm"
        @viewDetails="viewDetails($event)"
      )
      TaxonomyTreeView(
        v-else-if="controls.view === 'tree'"
        :taxonomy="taxonomy"
        @viewDetails="viewDetails($event)"
      )
    NioDialog(
      v-model="itemDetailsDialog"
    )
      TaxonomyItemDetails(
        v-if="itemDetailsDialog && showDetailsItem"
        :item="showDetailsItem"
        :index="itemIndex(showDetailsItem)"
        :count="itemCount"
        mode="view"
        @prev="prevItem"
        @next="nextItem"
        @close="closeItemDetailsDialog"
      )
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import TaxonomyTableView from './TaxonomyTableView'
import TaxonomyTreeView from './TaxonomyTreeView'
import TaxonomyItemDetails from '../shared/details/TaxonomyItemDetails'
import { TableColumns, initiallySelectedTableColumns } from './columns'
import Filters from './filters'
import { formatNumberVerbose } from '@narrative.io/tackle-box/src/modules/helpers'

export default {
  components: { TaxonomyTableView, TaxonomyTreeView, TaxonomyItemDetails },
  props: {
    taxonomy: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    menu: false,
    selected: null,
    controls: {
      view: 'tree',
      columnOptions: TableColumns,
      columns: JSON.parse(JSON.stringify(initiallySelectedTableColumns)),
      filters: Filters,
      activeFilterIndeces: [],
      searchTerm: '',
      sliderModels: {
        activeIdsCount: [0, 0],
        receivedIdsCount: [0, 0]
      }
    },
    itemDetailsDialog: false,
    showDetailsItem: null,
  }),
  computed: {
    itemCount() {
      return this.taxonomy.length
    },
    selectColumnsLabel() {
      return `Select Columns (${this.controls.columns.length})`
    },
    selectFiltersLabel() {
      return `Add Filters (${this.controls.filters.length})`
    },
    tableExceedsWidth() {
      return this.controls.columns.reduce((acc, column) => acc + column.width, 0) + 150  > 980 // + 150 for actions column
    }
  },
  mounted() {
    this.initializeIdsFilters()
  },
  methods: {
    initializeIdsFilters() {
      this.controls.filters.forEach(filter => {
        if (filter.name === 'activeIdsCount' || filter.name === 'receivedIdsCount') {
          filter.customControls.min = this.taxonomy.reduce((acc, item) => {
            if (item[filter.name] && acc === 0 || item[filter.name] < acc) {
              return item[filter.name]
            }
            return acc
          }, 0)
          filter.customControls.max = this.taxonomy.reduce((acc, item) => {
            if (item[filter.name] && !acc || item[filter.name] > acc) {
              return item[filter.name]
            }
            return acc
          }, 0)
          filter.customControls.value = [filter.customControls.min, filter.customControls.max]
          this.controls.sliderModels[filter.name] = filter.customControls.value
        }
      })
    },
    sliderChanged(filter, val) {
      filter.customControls.value = [val[0], val[1]]
    },
    showFilter(filter) {
      return !(filter.customControls.min === filter.customControls.max)
    },
    viewDetails(item) {
      this.showDetailsItem = item
      this.itemDetailsDialog = true
    },
    closeItemDetailsDialog() {
      this.showDetailsItem = null
      this.itemDetailsDialog = false
    },
    itemIndex(item) {
      return this.taxonomy.findIndex(taxonomyItem => taxonomyItem.id === item.id)
    },
    prevItem() {
      const index = this.itemIndex(this.showDetailsItem)
      if (index > 0) {
        this.showDetailsItem = this.taxonomy[index - 1]
      }
    },
    nextItem() {
      const index = this.itemIndex(this.showDetailsItem)
      if (index < this.taxonomy.length - 1) {
        this.showDetailsItem = this.taxonomy[index + 1]
      }
    },
    isFirstFilterOption(item) {
      return this.controls.filters.findIndex(filterItem => filterItem.name === item.name) === 0
    },
    filterDisabled(filter) {
      return (filter.name === 'activeIdsCount' || filter.name ==='receivedIdsCount') && !this.idsAvailable(filter)
    },
    idsAvailable(filter) {
      return filter.customControls?.max > 0
    },
    formaSliderThumbLabel(val) {
      return formatNumberVerbose(parseInt(val))
    },
    getThemeColor(colorName) {
      return getThemeColor(colorName)
    }
  }
};
</script>

<style lang="sass">
  .taxonomy-listing-table-filters
    .active-ids-controls
      display: block
      width: 100%


</style>


<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/_menu"
@import "@narrative.io/tackle-box/src/styles/mixins/_slider"

.taxonomy-listing
  ::v-deep .nio-dialog
    .nio-dialog-content
      height: 56.25rem
      overflow: scroll
  .controls
    display: flex
    width: 100%
    .view-selection
      display: flex
      border-radius: 0.5rem
      height: 40px
      overflow: hidden
      .radio
        height: 100%
        label
          display: flex
          justify-content: center
          align-items: center
          width: 56px
          height: 100%
          background: $c-white
          border: 1.5px solid $c-primary-lighter
          cursor: pointer
          color: $c-primary
        input[type="radio"]
          display: none
        input[type="radio"]:checked+label
          background: $c-canvas
          color: $c-primary-dark
          border-color: $c-primary-dark
        &.table
          label
            border-top-left-radius: 0.5rem
            border-bottom-left-radius: 0.5rem
            border-right: none
          input[type="radio"]:checked+label
        &.tree
          label
            border-top-right-radius: 0.5rem
            border-bottom-right-radius: 0.5rem
            border-left: 1.5px solid $c-primary-dark
          input[type="radio"]:checked+label
    .nio-select, .nio-text-field
      margin-bottom: 0rem
    & > .nio-select,
    & > .filter-control-activator
      width: 15rem
      flex-grow: 0
      ::v-deep .v-text-field__details
        display: none

    .search-input
      width: 15rem
      flex-grow: 0
    .control-disabled
      pointer-events: none !important
      opacity: 0.5
    & > * + *
      margin-left: 0.5rem
    .table-scroll-message
      align-self: flex-end
      margin-left: auto
    .fitler-control-menu
      background-color: red
    .filter-control-activator
      position: relative
      .nio-select
        margin-bottom: 0rem
        &.v-input--is-disabled ::v-deep .v-input__slot
          background-color: $c-white !important
      .filter-control-activator-btn
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        opacity: 1
        height: 2.5rem
        background: rgba(0, 0, 0, 0)
        &[aria-expanded="true"]
          border: 1px solid $c-primary
          border-radius: 0.5rem

      .v-menu__content
        top: 3.125rem !important
        left: 0rem !important
      ::v-deep .v-menu__content
        +nio-menu
        max-width: unset !important
        width: 21.875rem
        .v-ripple__container
          display: none
        .v-list-item
          position: relative
          padding: 0rem !important // this must be 0 to prevent a click from triggering filter activation while not checking checkbox
          .v-list-item__action,
            margin: 0rem
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 3rem
          .v-list-item__action .v-input
            flex-grow: 2
            .v-input__slot
              height: 3rem
              padding-left: 0.5rem
              label
                color: $c-primary-dark
                font-size: 1rem
          .custom-filter-controls
            width: 100%
            margin-top: 3rem // this must equal the height of the v-list-item__action element
            padding: 0rem 1.5rem 1.5rem 1.5rem
            .rate-card-filters
              .nio-p
                text-align: center
                margin: 1rem auto
                opacity: 0.5
            .nio-switch
              margin-top: 0.5rem
              .v-messages
                display: none
              .v-input--selection-controls__input
                transform: scale(0.8)
            .no-filter-ids
              text-align: center
              font-size: 1rem
              color: $c-primary-dark
            .v-slider
              margin-top: 1.5rem
              +nio-slider
              .v-slider__thumb-label
                background-color: rgba(0, 0, 0, 0) !important
                white-space: nowrap
  .display
    margin-top: 1rem
  .filters


</style>