<template lang="pug">
  .taxonomy-management-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80"
        color="#1438F5"
        indeterminate
      )
    .page-header
      h1.nio-h1.text-primary-darker Taxonomy Management
      NioButton(
        v-if="syncableCount > 0"
        :disabled="syncableCount === 0"
        iconName="utility-upload"
        normal-primary-prepend
        @click="sync"
      ) Sync ({{ syncableCount }})
    UploadTaxonomy(
      v-if="!loading && !taxonomy"
      @taxonomyCreated="taxonomyCreated($event)"
      @taxonomyError="taxonomyError($event)"
    )
    TaxonomyListing(
      v-if="!loading && taxonomy"
      :taxonomy="taxonomy"
    )
    NioDialog(
      v-model="syncing"
    )
      SyncingDialog(
        :totalCount="syncApiCallsTotalCount"
        :executedCount="syncApiCallsExecutedCount"
      )
    NioDialog(
      v-model="syncComplete"
    )
      SyncResultsDialog(
        :error="error"
        @ok="closeSyncComplete"
      )
    NioDialog(
      v-model="noProfileDialog"
    )
      NoProfileDialog(
        @ok="goToProfileSettings"
      )
    NioDialog(
      v-model="uploadErrorDialog"
    )
      ErrorDialog(
        heading="There was a problem generating your taxonomy."
        description="It looks like there was an error in the file you uploaded. Please check the file for errors and try again. If you continue to experience problems, please contact a member of the Narrative team."
        @close="uploadErrorDialog = false"
      )
</template>

<script>

import UploadTaxonomy from './upload/UploadTaxonomy.vue'
import TaxonomyListing from './listing/TaxonomyListing.vue'
import SyncingDialog from './shared/SyncingDialog.vue'
import SyncResultsDialog from './upload/SyncResultsDialog.vue'
import NoProfileDialog from './NoProfileDialog.vue'
import ErrorDialog from '../../shared/ErrorDialog.vue'
import { PathDelimiter } from '@/constants'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { baseUrl, setHeaders, getHeaders } from '@/utils/serviceLayer'
import {
  RateTypeToItemKeyMapping,
  replaceIdsWithUUIDs,
  getExistingTTDTaxonomy,
  sortTaxonomyByFullPath,
  attachTaxonomyEffectivePrices
} from '@narrative.io/tackle-box/src/modules/app/ttd-taxonomy/ttdTaxonomyModule'
import {
  getProfileSettings
} from '@/components/shared/profilesModule'
import axios from 'axios'

export default {
  components: {
    UploadTaxonomy,
    TaxonomyListing,
    SyncingDialog,
    SyncResultsDialog,
    NoProfileDialog,
    ErrorDialog
  },
  props: {
  },
  data: () => ({
    taxonomy: null,
    existingTaxonomyError: false,
    existingTaxonomyErrorMessage: null,
    loading: true,
    syncing: false,
    syncApiCallsTotalCount: null,
    syncApiCallsExecutedCount: null,
    syncComplete: false,
    noProfileDialog: false,
    uploadErrorDialog: false,
    error: {
      type: null,
      messages: []
    }
  }),
  computed: {
    syncableCount() {
      if (!this.taxonomy || this.taxonomy.length === 0) return 0
      return this.taxonomy.filter(item => !item.synced || item.hasChanged).length
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    setError(type, messages) {
      this.loading = false
      this.error = { type, messages }
      console.log("Global error updated: ", this.error)
    },
    async openApiInit(token) {
      setHeaders(token)
      await this.getProfileSettings()
      try {
        const taxonomy = await getExistingTTDTaxonomy(getHeaders(), baseUrl, this.nioUser.companyId)
        if (taxonomy?.length > 0) {
          this.taxonomy = taxonomy
        }
        this.loading = false
      } catch (error) {
        console.log(error)
        this.setError(error.type, error.messages)
      }
    },
    async getProfileSettings() {
      let existingProfile
      try {
        existingProfile = await getProfileSettings(baseUrl, getHeaders())
        if (existingProfile) {
          this.existingProfile = existingProfile
        } else {
          this.noProfileDialog = true
        }
      } catch (error) {
        this.noProfileDialog = true
      }
    },
    taxonomyCreated(taxonomy) {
      try {
        const sortedTaxonomy = sortTaxonomyByFullPath(taxonomy)
        this.taxonomy = sortedTaxonomy.map(item => {
          return {
            ...item,
            isContainer: this.itemIsContainer(item, sortedTaxonomy)
          }
        })
        replaceIdsWithUUIDs(this.taxonomy)
        attachTaxonomyEffectivePrices(this.taxonomy, true)
      } catch (error) {
        this.uploadErrorDialog = true
      }
    },
    taxonomyError(message) {
      this.setError("upload", message)
    },
    itemIsContainer(item) {
      return !item.buyable
    },
    async sync() {
      this.error.messages = []
      this.syncing = true
      this.syncComplete - false
      const newItemBatches = []
      const materializeReqBodies = []

      // const changedItems = []
      this.taxonomy.forEach(item => {
        if (!item.synced) {
          const parsedPath = item.fullPath.split(PathDelimiter) // TODO refactor
          if (newItemBatches[parsedPath.length - 1]) {
            newItemBatches[parsedPath.length - 1].push(item)
          } else {
            newItemBatches[parsedPath.length - 1] = [item]
          }
        } else if (item.hasChanged) {
          // changedItems.push(item) not supported in MVP
        }
        if (item.dataStreamId) {
          materializeReqBodies.push(this.makeMaterializeBody(item))
        }
      })

      this.syncApiCallsTotalCount = newItemBatches.reduce((acc, curr) => acc + curr.length, 0) + materializeReqBodies.length + 1 // +1 for rate details
      this.syncApiCallsExecutedCount = 0
      const newItemBatchesCopy = JSON.parse(JSON.stringify(newItemBatches))

      while (newItemBatchesCopy.length > 0) {
        const currBatch = newItemBatchesCopy.shift()
        try {
          await this.syncTaxonomyItemBatch(currBatch)
        } catch (error) {
          this.setError('sync', [...this.error.messages, error])
        }
      }
      try {
        for (const body of materializeReqBodies) {
          await this.$nioOpenApi.post('/data-shops/subscriptions/materialize', body)
          this.syncApiCallsExecutedCount++
          await new Promise(resolve => setTimeout(resolve, 2000))
        }
        await this.syncTaxonomyRateDetails(newItemBatches)
      } catch (error) {
        this.setError('sync', [...this.error.messages, error])
      }

      this.syncApiCallsExecutedCount++
      this.syncComplete = true
      this.syncing = false
      this.syncApiCallsTotalCount = null
      this.syncApiCallsExecutedCount = null
      this.syncComplete = true
      console.log("Sync complete")
      console.log("Sync errors", this.error.messages)

    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async syncTaxonomyItemBatch(batch) {
        const batchItems = []
        batch.forEach(item => {
          const reqBody = {
            provider_element_id: item.id,
            parent_element_id: item.parentElementId ? item.parentElementId : 'ROOT',
            display_name: item.displayName,
            buyable: item.buyable,
            description: item.description
          }
          batchItems.push(reqBody)
        })

        while (batchItems.length > 0) {
          const reqBody = batchItems.shift()
          try {
            await axios.post(`${baseUrl}/taxonomy`, reqBody, getHeaders())
            await new Promise(resolve => setTimeout(resolve, 2000))
            this.syncApiCallsExecutedCount++
          } catch (error) {
            console.log("Error creating batch", error)
            this.setError('sync', [...this.error.messages, error])
          }
        }
        return Promise.resolve()
    },
    makeMaterializeBody(item) {
      return {
        data_stream_id: item.dataStreamId,
        cadence: "monthly",
        read_once: false,
        destinations: [
          {
            profile_id: this.existingProfile.api_profile.id,
            quick_settings: {
              use_existing_taxonomy_elements : [item.id],
              targeting_time_to_live_in_minutes: 129600
            }
          }
        ]
      }
    },
    async syncTaxonomyRateDetails(newItemBatches) {
      const dataRates = []
      newItemBatches.flatMap(item => item).forEach(item => {
        let partnerIds = []
        let advertiserIds = []
        if (item.partnerRateCard?.ids?.length > 0) {
          partnerIds = item.partnerRateCard.ids.split('\n')
        } else if (item.advertiserRateCard?.ids?.length > 0) {
          advertiserIds = item.advertiserRateCard.ids.split('\n')
        }
        if (item.systemRateCard) {
          dataRates.push(this.makePartnerAdvertiserRateCard(item, 'System'))
        }
        if (window.location.host === 'thetradedesk-connector.narrative.tools') { // only sync advertiser and partner rates if in prod
          advertiserIds.forEach(id => {
            dataRates.push(this.makePartnerAdvertiserRateCard(item, 'Advertiser', id))
          })
          partnerIds.forEach(id => {
            dataRates.push(this.makePartnerAdvertiserRateCard(item, 'Partner', id))
          })
        }
      })
      const dataRatesResp = await axios.post(`${baseUrl}/data-rates/batches`, { data_rates: dataRates }, getHeaders())
      return await Promise.resolve(dataRatesResp)
    },
    makePartnerAdvertiserRateCard(item, type, id) {
      return {
        provider_element_id: item.id,
        rate_level: type,
        partner_id: type === 'Partner' ? id : null,
        advertiser_id: type === 'Advertiser' ? id : null,
        rate_type: "Hybrid",
        percent_of_media_cost_rate: item[RateTypeToItemKeyMapping[type]].revenueShare / 100,
        cpm_rate: {
          amount: item[RateTypeToItemKeyMapping[type]].cpmCap,
          currency_code: "USD",
        }
      }
    },
    closeSyncComplete() {
      this.syncComplete = false
      window.location.reload()
    },
    goToProfileSettings() {
      parent.postMessage({
        name: 'pageNavigation',
        payload: 'profile-settings'
      },"*")
    }
  },
}
</script>

<style lang="sass" scoped>
.taxonomy-management-page
  padding: 1.5rem
  .page-header
    display: flex
    justify-content: space-between
    align-items: flex-start
    margin-bottom: 1.5rem

</style>